var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"gateway-container",attrs:{"element-loading-background":"white"}},[(_vm.loading)?_c('Loader'):_vm._e(),_vm._v(" "),(!_vm.loading || _vm.tableData.length > 0)?_c('el-row',[_c('layout-toolbar',{staticStyle:{"margin":"10px 10px"},attrs:{"justify":"end"}},[_c('er-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"inputType":"search","size":"mini","suffix-icon":"el-icon-search","placeholder":_vm.$t('Comn_search')},on:{"change":_vm.handelSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('er-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.buttonloading,"disabled":_vm.isReadOnly},on:{"click":_vm.handleSaveDetails}}),_vm._v(" "),_c('er-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"size":"mini","btnType":"cancel","showLabel":true,"disabled":_vm.isReadOnly},on:{"click":_vm.handleCancel}}),_vm._v(" "),_c('er-dropdown',{staticClass:"highlight",attrs:{"placement":"bottom","trigger":"click","listOfItems":['Comn_download_as_excel', 'Comn_download_as_pdf']},on:{"item-click":_vm.handleTableDataDownload},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(item.value)))]}}],null,false,1598760758)},[_c('template',{staticClass:"el-dropdown-link download_button",slot:"suffix"},[_c('span',{staticClass:"material-icons-outlined action-icon"},[_vm._v("\n            file_download\n          ")])])],2)],1),_vm._v(" "),_c('el-row',{staticClass:"settings-gateway-table",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":24,"md":20,"lg":15,"xl":10}},[_c('er-data-tables',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:_vm.$i18n.locale,ref:"gatewaySettingsTable",attrs:{"uniqueId":"gateway-settings__table","tableData":_vm.tableData,"columns":_vm.tableColumns,"elTableProps":_vm.tableProps,"actionColumn":false,"size":"mini","pagination-props":_vm.$constants.table['pagination-props'],"default-sort":{ prop: 'code', order: 'ascending' },"type":"white-header-table","searchOnTableData":_vm.search},scopedSlots:_vm._u([{key:"code",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"code","withoutHighlightStrings":row.data.code}})]}},{key:"alias_name",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini"},on:{"change":function($event){return _vm.handleGatewayChangeInTable($event, row.data)}},model:{value:(row.data.title),callback:function ($$v) {_vm.$set(row.data, "title", $$v)},expression:"row.data.title"}})]}},{key:"location_id",fn:function(ref){
var row = ref.row;
return [_c('er-select',{staticClass:"location-selector",attrs:{"value-key":row.data.location_id,"size":"mini"},on:{"change":function($event){return _vm.handleGatewayChangeForLocationInTable($event, row.data)}},model:{value:(row.data.locations[0]),callback:function ($$v) {_vm.$set(row.data.locations, 0, $$v)},expression:"row.data.locations[0]"}},_vm._l((_vm.getLocationOfUser),function(location){return _c('el-option',{key:location._id,attrs:{"value":location._id,"label":location.name}},[_vm._v("\n                "+_vm._s(location.name)+"\n              ")])}),1)]}},(_vm.showAllowOtherLocationDisplay)?{key:"allow_other_location_device",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"disabled":_vm.shouldDisable(row.data)},on:{"change":function($event){return _vm.handleGatewayChangeInTable($event, row.data)}},model:{value:(row.data.allow_all_devices),callback:function ($$v) {_vm.$set(row.data, "allow_all_devices", $$v)},expression:"row.data.allow_all_devices"}})]}}:null],null,true)},[_c('template',{slot:"empty"},[_c('span',[_c('img',{staticClass:"no-data__img",attrs:{"src":require("@/assets/no_data.svg")}})]),_vm._v(" "),_c('span',{staticClass:"no-data__text"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Comn_no_data_connection")))])])])],2)],1)],1)],1):_c('er-no-ponds',{attrs:{"tabData":_vm.tabData,"disabled":_vm.isReadOnly}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }