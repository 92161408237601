<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: locationManagement.vue
Description: This file contains UI components of locations tab from settings page. It contians location settings data table
-->
<template>
  <el-row
    type="flex"
    justify="center"
    class="location-management"
    element-loading-background="white"
    v-loading="loading"
  >
    <el-col :span="12">
      <el-row
        type="flex"
        align="middle"
        justify="end"
        class="action-toolbar-row"
      >
        <el-col :span="9">
          <er-input
            inputType="search"
            size="mini"
            v-model="searchLocation"
            suffix-icon="el-icon-search"
            :placeholder="ftm__capitalize($tc('Comn_location.case.lower', 1))"
            @change="handelSearchChange"
          ></er-input>
        </el-col>
        <el-col :span="3">
          <er-dropdown
            placement="bottom"
            @item-click="handleTableDataDownload"
            trigger="click"
            class="highlight"
            :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
          >
            <template class="el-dropdown-link download_button" slot="suffix">
              <span class="material-icons-outlined action-icon">
                file_download
              </span>
            </template>
            <template v-slot:item-title="{ item }">{{
              $t(item.value)
            }}</template>
          </er-dropdown>
        </el-col>
      </el-row>
      <el-row justify="center" class="table-container">
        <er-data-tables
          ref="locationtable"
          class="locations-table"
          uniqueId="location-table"
          :key="$i18n.locale"
          :table-data="locations"
          :el-table-props="table_props"
          :columns="tableColumns"
          :actionColumn="false"
          type="white-header-table"
          :searchOnTableData="searchLocation"
        >
          <template v-slot:location="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="name"
              :withoutHighlightStrings="ftm__capitalize(row.data.name)"
            />
          </template>
          <template v-slot:actions="{ row }">
            <el-popover
              style="display: inline-flex"
              popper-class="location_management"
              placement="bottom"
              :title="$t('Comn_edit')"
              width="200"
              v-model="row.data.visible"
              @show="handlePopperEvent(row.data)"
            >
              <el-form>
                <el-form-item :error="errormessage">
                  <el-input
                    size="small"
                    class="edit-input-location"
                    :ref="`inputLocation${row.data._id}`"
                    v-model="location_name"
                  >
                  </el-input>
                </el-form-item>
              </el-form>

              <el-row type="flex" justify="end" style="margin-top: 15px">
                <er-button
                  btnType="save"
                  :showLabel="true"
                  :showIcon="true"
                  size="mini"
                  :disabled="isReadOnly"
                  :loading="loading"
                  @keyup.enter.native="handleUpdateLocationName(row.data)"
                  @click="handleUpdateLocationName(row.data)"
                ></er-button>
                &nbsp;&nbsp;
                <er-button
                  btnType="cancel"
                  size="mini"
                  :showLabel="true"
                  :disabled="isReadOnly"
                  @click="handleClosePopper(row.data)"
                  >{{ $t("Comn_cancel") }}</er-button
                >
              </el-row>

              <er-button
                :showLabel="true"
                :showIcon="true"
                type="text"
                size="mini"
                slot="reference"
                btnType="edit"
                :disabled="isReadOnly"
              ></er-button>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
            <span style="display: inline-table">
              <er-button
                :showLabel="true"
                :showIcon="true"
                type="text"
                size="mini"
                btnType="delete"
                :disabled="isReadOnly"
                @click="handleDeleteClick(row.data)"
              ></er-button>
            </span>
          </template>
        </er-data-tables>
      </el-row>
    </el-col>
    <er-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :title="$t('Comn_no_deletelocations')"
      custom-class="location_dialog"
    >
      <el-timeline>
        <el-timeline-item v-for="info in infoLangKeys" :key="info">
          <i18n :path="info" tag="span">
            <template v-slot:location>
              <strong class="captalize">{{ location_name }}</strong>
            </template>
          </i18n>
        </el-timeline-item>
      </el-timeline>
      <span class="el-row el-row--flex">
        <el-checkbox v-model="checked"></el-checkbox>
        <p class="checkbox-content">{{ $t("agree_delete_location_msg") }}</p>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-row>
          <er-button
            :loading="loading"
            :disabled="!checked || isReadOnly"
            btnType="confirm"
            size="mini"
            @click="handleRemoveLocation"
            >{{ $t("Comn_confirm") }}</er-button
          >
        </el-row>
      </span>
    </er-dialog>
  </el-row>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import filtersMixin from "@/mixins/filtersMixin";
import { mapActions, mapGetters } from "vuex";
import exportFileDownload from "@/mixins/exportFileDownload";

export default {
  mixins: [errorHandlerMixin, redirectsMixin, filtersMixin, exportFileDownload],
  data: function() {
    return {
      loading: false,
      dialogVisible: false,
      errormessage: "",
      data: [],
      nochange: false,
      checked: false,
      location_name: "",
      visible: false,
      selectedLocationObj: {},
      table_props: {
        border: false,
        width: "100%",
        size: "mini",
        height: "calc(100vh - var(--table-gutter))",
        defaultSort: {
          prop: "name",
          order: "ascending"
        }
      },
      disabled: true,
      searchLocation: "",
      infoLangKeys: [
        "all_schedules_delete_in_location_msg",
        "all_devices_delete_in_location_msg",
        "all_ponds_delete_in_location_msg",
        "delete_location_msg"
      ]
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getCurrUserLocation: "getCurrUserLocation"
    }),
    locations() {
      const dataArr = this.getLocations.map(item => {
        return [item._id, item];
      });
      const locationArray = [...new Map(dataArr).values()];
      return locationArray.filter(
        location =>
          this.seachLocation !== "" &&
          this.trim_nameSpace(location.name)
            .toLowerCase()
            .includes(this.searchLocation.toLowerCase())
      );
    },
    getLocations: {
      get() {
        const locations = [];
        this.$store.getters["user/getUserLocations"].forEach(
          ({ name, _id }) => {
            locations.push({ _id, name, visible: false });
          }
        );
        return locations;
      },
      set(value) {
        this.$store.dispatch("user/fetchAllUserLocations");
      }
    },
    tableColumns() {
      return {
        location: {
          prop: "name",
          label: this.ftm__capitalize(this.$tc("Comn_location.case.lower", 1)),
          minWidth: 200,
          maxWidth: 200,
          sortable: true,
          sortMethod: this.$commonUtils.naturalSort("name"),
          searchProperty: "name",
          enableSearch: true,
          downloadOption: true
        },
        actions: {
          prop: "actions",
          label: this.$t("Comn_actions"),
          minWidth: 100,
          maxWidth: 100,
          downloadOption: false
        }
      };
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.location.label,
          dataKey: "name",
          emptyValue: "--",
          name: "location",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        }
      ];
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.locationtable) {
        this.$refs.locationtable.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
  },
  methods: {
    ...mapActions("user", {
      fetchUserLocations: "fetchUserLocations",
      updateCurrUserLocation: "updateCurrUserLocation",
      setCurrUserLocation: "setCurrUserLocation",
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.searchLocation !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - Locations - Search" });
      }
    },
    handleTableDataDownload(downloadtype) {
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
      this.mixPanelEventGenerator({ eventName: "Settings - Locations - Download" });
    },
    xceldownload() {
      this.exp__Download(
        this.$refs.locationtable.$refs.dataTables.sortedData,
        this.downloadColumns,
        "xcel"
      );
    },
    pdfdownload() {
      this.exp__Download(
        this.$refs.locationtable.$refs.dataTables.sortedData,
        this.downloadColumns,
        "pdf"
      );
    },

    handleRemoveLocation: async function() {
      // const loading = this.$loading({ fullscreen: true });
      this.loading = true;
      try {
        await this.$store.dispatch(
          "user/deleteLocation",
          this.selectedLocationObj._id
        );
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Comn_location_del_success"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({ eventName: "Settings - Locations - Delete" });
        await this.fetchUserLocations();
        if (this.selectedLocationObj._id === this.getCurrUserLocation._id) {
          this.setCurrUserLocation();
          this.rdm__navigateToPathWithLocationId({
            query: {
              ...this.$route.query,
              location_id: this.getCurrUserLocation._id
            }
          });
        }
        this.checked = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("failed_to_complete_the_action_for_resource", {
            action: this.$t("Comn_confirm"),
            resource_name: this.location_name
          }),
          duration: 5000,
          type: "error"
        });
      } finally {
        // loading.close();
        this.loading = false;
        this.dialogVisible = false;
      }
    },

    handleUpdateLocationName: async function(locationDetails) {
      this.ehm__unhandledErrorMessage = "";
      const filtLocations = this.locations
        .map(x => x.name.toLowerCase())
        .includes(this.trim_nameSpace(this.location_name).toLowerCase());

      if (locationDetails.name !== this.location_name && filtLocations) {
        this.errormessage = this.$t("pond-name-already-taken");
      } else {
        this.errormessage = "";
        this.loading = true;
        try {
          this.$gblUAMIsHighPriorityUsersRestricted();
          const currLocation = await this.$store.dispatch(
            "user/updateLocationName",
            {
              locationId: locationDetails._id,
              location_name: this.trim_nameSpace(this.location_name)
            }
          );
          await this.updateCurrUserLocation(currLocation);
          this.mixPanelEventGenerator({ eventName: "Settings - Locations - Edit - Save" });
          this.location_name = "";
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Comn_location_upd_success"),
            duration: 5000,
            type: "success"
          });
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      }
    },
    handleClosePopper: function(location) {
      location.visible = false;
      this.location_name = "";
      this.errormessage = "";
    },
    handlePopperEvent: function(location) {
      this.errormessage = "";
      try {
        this.$gblUAMIsHighPriorityUsersRestricted();
        this.location_name = this.trim_nameSpace(location.name);
        this.$nextTick(() => {
          this.$refs[`inputLocation${location._id}`].$refs.input.select();
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleDeleteClick(location) {
      try {
        this.$gblUAMIsHighPriorityUsersRestricted();
        this.location_name = this.trim_nameSpace(location.name);
        this.selectedLocationObj = location;
        this.dialogVisible = true;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    }
  }
};
</script>

<style lang="scss">
.edit-input-location {
  .el-input__inner {
    text-transform: capitalize;
  }
}
.location-management {
  @include responsiveProperty(--table-gutter, 213px, 212px, 220px);
  padding-top: 10px;
  padding-bottom: 10px;
  table {
    width: 520px !important;
  }
  .cell {
    // text-transform: capitalize;
    letter-spacing: 0.5px;
  }
  .action-toolbar-row {
    margin-bottom: 10px;
    .el-input--mini .el-input__inner {
      font-size: inherit;
    }
  }
  .locations-table {
    width: 100%;
  }
}

.location_dialog {
  background: #fcfcfc;
  border-radius: 10px;
  .el-dialog__body {
    .el-row {
      .checkbox-content {
        font-size: 13px !important;
      }
    }
    .el-timeline {
      .el-timeline-item__wrapper {
        span {
          font-size: 13px !important;
        }
      }
    }
  }
  .el-dialog__header {
    color: white;
    background-color: #44a1ff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .el-dialog__headerbtn {
    .el-dialog__close {
      color: white;
    }
  }
  .captalize {
    text-transform: capitalize;
  }
  .el-timeline-item__content {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .checkbox-content {
    margin-left: 10px;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    word-break: break-word;
  }
}
.location_management {
  .locations-table {
    width: 100%;
  }
  .el-popover__title {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );

    color: #0a2463;
    line-height: 1;
    margin-bottom: 12px;
  }

  .er-button.el-button--mini:not(.is-circle) {
    padding: 5px 10px;

    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
}
</style>
